<template>
	<div>
		<div class="utility_card">
			<div class="utility_card_area">
				<ul
					v-if="card_list.length > 0"
				>
					<li
						v-for="(item, index) in card_list"
						:key="'item_' + index"
						class=""
						@click="onCard(item)"
					>
						<div class="">
							<button>
								<img
									:src="$request.upload_url(item.nft_card_img_url)"
									:alt="item.name">
								<!-- TODO : 아이템 장착카드시 표시 -->
								<div
									class="lock"
								><span class="hide">{{  $language.nft.staking_card }}</span></div>
							</button>
						</div>
					</li>
				</ul>
				<div
					v-else
					class="list_none"
					style="overflow: hidden"
				>
					<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
					<span>{{  $language.common.Not_found_history }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'mafia037-1'
	, props: ['user', 'card_list']
	, components: {}
	, data: function(){
		return {
			program:{
				name: 'STAKING NFT'
			}
			, items: []
			, is_0191: false
			, item_card: {

			}
			, item_search: {
				page: 1
				, list_cnt: 1
			}
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_staking_nft
					,data: {
						member_number: this.user.member_number
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
					, type: true
				})

				if(result.success){
					if(this.items.length > 0){
						this.items = this.items.concat(result.data.stakg_nft_card_list)
					}else{
						this.items = result.data.stakg_nft_card_list
					}

					if(result.data.stakg_nft_card_list.length > 0){
						this.item_search.page++
						await this.getData()
					}
				}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message})
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onCard: function(item){
			this.$bus.$emit('to', { name: 'mafia0372', params: {id: item.nft_card_number }})
		}
		, offCard: function(){
			this.item_card = {}
			this.is_0191 = false
		}
	}
	, created() {
		console.log(this.items)
		this.items = this.card_list
	}
}
</script>