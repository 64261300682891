<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class=" size-px-16 radius-20 overflow-hidden">
				<div class="bg-white">
					<div class="pa-20 justify-space-between under-line "><h3>{{ $language.common.staking}} {{ $language.staking.txt_reward_withdrawal}}</h3><v-icon @click="$emit('cancel')">mdi-close-circle</v-icon></div>
					<div class="pa-20">
						<div>
							<div>{{ $language.staking.txt_reward_withdrawal_possible_quantity}} </div>
							<div class="mt-10"><input
								v-model="item.wthdwl_possible_coin_token_quantity"
								disabled
								class="box radius-20 width-100 pa-10-20"
								/>
							</div>
						</div>
						<div class="mt-20">
							<div>{{ $language.staking.txt_reward_withdrawal_complete_quantity}} </div>
							<div class="mt-10 flex-row justify-space-between">
								<div class="mr-10">
									<input
										v-model="item.wthdwl_request_coin_token_quantity"
										class="box  pa-10-20 radius-20 width-100"
										type="number"
										:rules="[$rules.demical(item, 'wthdwl_request_coin_token_quantity', { min:8, max: 8})]"
									/>
								</div>
								<div>
									<button
										class="btn_fill_blue pa-10-20 radius-20"
										@click="toMax"
									>{{ $language.staking.txt_reward_withdrawal_full_quantity}}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="btn_area">
					<button
						class=" btn_fill_blue pa-10"
						@click="onPin"
					>{{  $language.staking.txt_reward_withdrawal }}</button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'mafia0192'
	,
	components: {PopupLayer},
	props: ['user']
	,  data: function(){
		return {
			program: {
				name: ''
				, title: ''
			}
			, item: {
				wthdwl_request_coin_token_quantity: 0
				, wthdwl_possible_coin_token_quantity: 0
			}
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_staking_reward_info
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
				if(result.success){
					this.item = result.data
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postReward: async function(pin_number){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_staking_reward
					, data: {
						member_number: this.user.member_number
						, pinnumber: pin_number
						, wthdwl_request_coin_token_quantity: this.item.wthdwl_request_coin_token_quantity
					}
					, type: true
				})
				if(result.success){
					this.item = result.data
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.$bus.$off('pinCallback')
				this.$emit('cancel')
			}
		}

		, onPin: function(){
			if(this.item.wthdwl_request_coin_token_quantity > 0){
				if(Number(this.item.wthdwl_request_coin_token_quantity) > Number(this.item.wthdwl_possible_coin_token_quantity)){
					this.$bus.$emit('notify', { type: 'error', message: this.$language.staking.txt_reward_withdrawal_error2})
					this.item.wthdwl_request_coin_token_quantity = this.item.wthdwl_possible_coin_token_quantity
				}else{

					this.$bus.$emit('onPin', 'check')
					this.$bus.$on('pinCallback', (type, pin) => {
						this.postReward(pin)
					})
				}
			}else{
				this.$bus.$emit('notify', { type: 'error', message: this.$language.staking.txt_reward_withdrawal_error})
			}
		}

		, toMax: function(){
			this.$set(this.item, 'wthdwl_request_coin_token_quantity', this.item.wthdwl_possible_coin_token_quantity)
		}
	}

	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>