<template>
	<div class="half_layer_wrap">
		<div class="half_layer_wrap_inner">
			<article class="half_view">
				<div class="layer_title">
					<div class="tit_tab">
						<h3 class="focus">STAKING {{  $language.common.history }}</h3>
					</div>
				</div>
				<div class="layer_table">
					<table class="nft_info_table">
						<colgroup>
							<col style="width: 75%">
							<col style="width: 25%">
						</colgroup>
						<thead>
						<tr>
							<th class="t-left">{{  $language.common.time }}</th>
							<th class="t-right">Mining</th>
						</tr>
						</thead>
						<tbody>
						<template
							v-if="items.length > 0"
						>
							<tr
								v-for="(item, index) in items"
								:key="'item_' + index"
							>
								<td>{{ item.reward_daytime }}</td>
								<td class="tb_ing_gray">{{  item.reward_token_quantity }}</td>
							</tr>
						</template>
						<tr
							v-else
						>
							<td colspan="3" class="td_list_none">
								<div class="list_none">
									<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
									<span>{{  $language.common.Not_found_history }}</span>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</article>
			<button class="btn_close" @click="$emit('cancel')">
				<i class="icon icon-close"></i>
			</button>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
export default {
	name: 'mafia038'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'STAKING ' + this.$language.common.history
      }
			, items: []
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_mining_list
					, data: {
						member_number: this.user.member_number
						, page_number: this.$language.base.page_number
						, pagerecnum: this.$language.base.pagerecnum
					}
					, type: true
				})
				if(result.success){
					this.items = result.data.stakg_nft_card_reward_list
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>