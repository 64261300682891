<template>
	<div class="section_wrap pt-140 pb-80">
		<div class="container">
			<div class="row">
				<!-- 애니메이션 -->
				<div class="roket_warp">
					<div class="roket_area">
						<div class="roket-container">

							<div class="wing-left"></div>
							<div class="wing-right"></div>
							<div class="exhaust"></div>

							<div class="capsule">
								<div class="top">
									<div class="shadow"></div>
								</div>
								<div class="base"></div>
							</div>

							<div class="window-big"></div>
							<div class="window-small"></div>

							<div class="fire-1"></div>
							<div class="fire-2"></div>
							<div class="fire-3"></div>
							<div class="fire-4"></div>

							<div class="spark-1"></div>
							<div class="spark-2"></div>
							<div class="spark-3"></div>
							<div class="spark-4"></div>

							<div class="star star-1"></div>
							<div class="star star-2"></div>
							<div class="star star-3"></div>
							<div class="star star-4"></div>
							<div class="star star-5"></div>
							<div class="star star-6"></div>
							<div class="star star-7"></div>
							<div class="star star-8"></div>
							<div class="star star-9"></div>
							<div class="star star-10"></div>
							<div class="star star-11"></div>
							<div class="star star-12"></div>
							<div class="star star-13"></div>
							<div class="star star-14"></div>
							<div class="star star-15"></div>
							<div class="star star-16"></div>
							<div class="star star-17"></div>
							<div class="star star-18"></div>
							<div class="star star-19"></div>
							<div class="star star-20"></div>

						</div>
					</div>
				</div>
				<!-- //애니메이션 -->
				<!-- 채굴한 양 -->
				<div class="mined">
					<span class="tit">TOTAL CUMULATIVE MINING</span>
					<strong class="min_count">{{ Number(item_main.tot_reward_token_quantity).toFixed(2) }}</strong>
				</div>
				<!-- //채굴한 양 -->
				<div class="intro_line">
					<span class="hide">{{ $language.agreement.dividingLine }}</span>
				</div>
				<!-- 금일 채굴양 -->
				<div class="mining">
					<span class="tit">TODAY</span>
					<strong class="min_count">{{ Number(item_main.today_reward_token_quantity).toFixed(2) }}</strong>
				</div>

				<div
					class="text-center mt-30 "
				>
					<!-- 보상 인출 -->
					<button class="btn_l btn_fill_blue mr-10 size-px-14" @click="is_0192 = true"><em class="gray_600 pr-5 "> {{ $language.staking.txt_reward_withdrawal }} </em></button>

					<!-- 보상 인출 내역 -->
					<button class="btn_l btn_fill_blue size-px-14" @click="is_0193 = true"><em class="gray_600 pr-5"> {{ $language.staking.txt_reward_withdrawal_history }} </em></button>
				</div>
				<div
					v-if="false"
					class="btn_area pt-30"
				>
					<button
						class="btn_l btn_fill_blue" @click="is_037 = true"
					><em class="gray_600 pr-5">STAKING </em><strong> {{ item_main.stakg_coin_count }}</strong></button>
					<button
						v-if="false"
						class="btn_l btn_fill_blue" @click="is_038 = true"
					><em class="gray_600 pr-5">STAKING </em>{{  $language.common.history }}</button>
				</div>
			</div>
		</div>

		<mafia0371
			:user="user"
			:card_list="items_card"

			@cancel="is_037 = false"

			class="mt-30"
		></mafia0371>

		<mafia038
			v-if="is_038"
			:user="user"

			@cancel="is_038 = false"
		></mafia038>

		<mafia0191
			v-if="is_0191"
			:user="user"

			@cancel="is_0191 = false"
		></mafia0191>

		<mafia0192
			v-if="is_0192"
			:user="user"

			@cancel="is_0192 = false"
		></mafia0192>

		<mafia0193
			v-if="is_0193"
			:user="user"

			@cancel="is_0193 = false"
		></mafia0193>
	</div>
</template>

<script>

	import mafia038 from '@/view/Staking/mafia038'
	import Mafia0371 from "@/view/Staking/mafia037-1";
	import mafia0191 from "@/view/Staking/mafia037-2";
	import mafia0192 from "@/view/Staking/mafia019-2";
	import mafia0193 from "@/view/Staking/mafia019-3";
	export default {
		name: 'mafia036'
		, props: ['user']
		, components: {Mafia0371, mafia038, mafia0191, mafia0192, mafia0193 }
		, data: function(){
			return {
				program: {
					name: this.$language.common.staking
				}
				, mining: {
					total: 0
					, today: 0
					, count: 0
				}
				, is_038: false
				, is_037: false
				, is_0191: false
				, is_0192: false
				, is_0193: false
				, item_main: {

				}
				, item_search: {
					page: 1
					, list_cnt: 10
				}
				, items_card: []
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_staking_main
						, data: {
							member_number: this.user.member_number
							, page_number: this.item_search.page
							, pagerecnum: this.item_search.list_cnt
						}
						, type: true
					})
					if(result.success){
						this.item_main = result.data
						if(this.items_card.length > 0 ){
							this.items_card = this.items_card.concat(result.data.stakg_nft_card_list)
						}else{
							this.items_card = result.data.stakg_nft_card_list
						}
						if(result.data.stakg_nft_card_list.length > 0){
							this.item_search.page++
							await this.getData()
						}
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>