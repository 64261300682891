<template>
	<div class="half_layer_wrap">
		<div class="half_layer_wrap_inner">
			<article class="half_view">
				<div class="layer_title">
					<div class="tit_tab">
						<!-- 인출 내역 -->
						<h3 class="focus">{{  $language.staking.txt_reward_withdrawal_history}}</h3>
					</div>
				</div>
				<div class="layer_table">
					<table class="nft_info_table">
						<colgroup>
							<col style="width: 50%">
							<col style="width: 50%">
						</colgroup>
						<thead>
						<tr>
							<!-- 요청일 -->
							<th class="t-left">{{  $language.staking.txt_reward_withdrawal_date }}</th>
							<!-- 완료일 -->
							<th class="t-right">{{  $language.staking.txt_reward_withdrawal_date2 }}</th>
						</tr>
						</thead>
						<tbody>
						<template
							v-if="items.length > 0"
						>
							<template
								v-for="(item, index) in items"
							>
								<tr
									:key="'item_' + index"
								>
									<!-- 요청일자 -->
									<td>{{ item.wthdwl_requestdate | transDate}}</td>
									<!-- 완료일자 -->
									<td class="text-right">{{ item.wthdwl_compdt | transDate}}</td>
								</tr>
								<tr
									:key="'item2_' + index"
									class="under-line"
								>
									<td colspan="2" class="tb_ing_gray">
										<div class="flex-row justify-space-between">
											<!-- 수량 -->
											<span>{{  $language.staking.txt_reward_withdrawal_qantity }}</span>
											<span>{{  item.wthdwl_coin_token_quantity | makeComma(8)}}</span>
										</div>
									</td>
								</tr>
							</template>
						</template>
						<tr
							v-else
						>
							<td colspan="3" class="td_list_none">
								<div class="list_none">
									<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
									<!-- 이력이 없습니다 -->
									<span>{{  $language.common.Not_found_history }}</span>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</article>
			<button class="btn_close" @click="$emit('cancel')">
				<i class="icon icon-close"></i>
			</button>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
export default {
	name: 'mafia0193'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'STAKING ' + this.$language.common.history
			}
			, items: []
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_staking_reward
					, data: {
						member_number: this.user.member_number
						, page_number: this.$language.base.page_number
						, pagerecnum: this.$language.base.pagerecnum
					}
					, type: true
				})
				if(result.success){
					if(this.items.length > 0){
						this.items = this.items.concat(result.data.reward_wthdwl_particulars_list)
					}else{
						this.items = result.data.reward_wthdwl_particulars_list
					}
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>